import { graphql } from 'gatsby'
import React from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import styled from 'styled-components'

import { Card, Tabs } from 'mf-ui-components'

import { renderBlock } from '../../PageBlocks'
import { marginBreakpoints, hideBlockAt } from '../../../utils/commonAttrs'
import { fromBreakpoint } from '../../../utils/style/breakpoint'
import { spacing } from '../../../utils/ui/spacing'

const TabsContainer = styled(Container)`
  ${props => marginBreakpoints(props, 0)};
  ${props => hideBlockAt(props)}
`

const StyledCard = styled(Card)`
  padding: ${spacing('md')};

  ${fromBreakpoint('md')} {
    padding: ${spacing('lg')};
  }

  & .container {
    padding-right: ${spacing('md')};
    padding-left: ${spacing('md')};
  }
`

export default function TabsBlock({
  id,
  marginBottom,
  hiddenAt,
  tabs,
  content,
  mediaItems,
}) {
  return (
    <TabsContainer id={id} marginBottom={marginBottom} hiddenAt={hiddenAt}>
      <Row>
        <Col>
          <StyledCard>
            <Tabs stretch>
              {JSON.parse(tabs).map((tab, index) => (
                <Tabs.TabPane tab={tab.title} key={index}>
                  {content[index].map((block, blockIndex) =>
                    renderBlock(mediaItems, block, blockIndex)
                  )}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </StyledCard>
        </Col>
      </Row>
    </TabsContainer>
  )
}

export const query = graphql`
  fragment tabsFields on WPGraphQL_MfblocksTabsBlock {
    name
    attributes {
      tabs
      id
      hiddenAt
      marginBottom
    }
    innerBlocks {
      ...tabFields
    }
  }

  fragment tabFields on WPGraphQL_MfblocksTabBlock {
    name
    innerBlocks {
      ...paragraphFields
      ...listFields
      ...buttonFields
      ...iconFeatureFields
      ...imageFields
      ...textLinkFields
      ...pricingFields
      ...nestedColumnsFields
      ...headingFields
      ...accordionFields
      ...faqFields
      ...featureBoxFields
      ...featureCardFields
      ...iconFields
      ...pensionCalcFields
      ...pressItemFields
      ...tableFields
      ...teamFields
      ...timelineFields
      ...hrFields
      ...modelPortfoliosFields
      ...expectedPerformanceFields
      ...assetsBreakdownFields
    }
  }
`
